var tipo_dispositivo = 1;

function replaceAll(find, replace, str) {
    return str.replace(new RegExp(find, 'g'), replace);
}

function createOdontogram() {
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    tipo_dispositivo = 1;
  }else{

    tipo_dispositivo = 0;
    if ( /Safari/i.test(navigator.userAgent) ) {
      if ( /Chrome/i.test(navigator.userAgent) ) {
        tipo_dispositivo = 0; //chrome en mac
      }else{
        tipo_dispositivo = 1; //safari en mac
      }
    }
  }

  console.log(tipo_dispositivo);
  //Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.69 Safari/537.36
  //Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.1 Safari/605.1.15
  //console.log(navigator.userAgent);

    var htmlLecheLeft = "",
        htmlLecheRight = "",
        htmlLeft = "",
        htmlRight = "",
        a = 1;
    for (var i = 9 - 1; i >= 1; i--) {
        //Dientes Definitivos Cuandrante Derecho (Superior/Inferior)
        htmlRight += '<div data-name="value" id="dienteindex' + i + '" class="diente">' +
            '<span class="label label-info numeros">index' + i + '</span>' +
            '<div id="tindex' + i + '" class="cuadro arriba click">' +
            '</div>' +
            '<div id="lindex' + i + '" class="cuadro izquierdo click">' +
            '</div>' +
            '<div id="bindex' + i + '" class="cuadro debajo click">' +
            '</div>' +
            '<div id="rindex' + i + '" class="cuadro derecha click click">' +
            '</div>' +
            '<div id="cindex' + i + '" class="centro click">' +
            '</div>' +
            '</div>';
        //Dientes Definitivos Cuandrante Izquierdo (Superior/Inferior)
        htmlLeft += '<div id="dienteindex' + a + '" class="diente">' +
            '<span class="label label-info numeros">index' + a + '</span>' +
            '<div id="tindex' + a + '" class="cuadro arriba click">' +
            '</div>' +
            '<div id="lindex' + a + '" class="cuadro izquierdo click">' +
            '</div>' +
            '<div id="bindex' + a + '" class="cuadro debajo click">' +
            '</div>' +
            '<div id="rindex' + a + '" class="cuadro derecha click click">' +
            '</div>' +
            '<div id="cindex' + a + '" class="centro click">' +
            '</div>' +
            '</div>';
        if (i <= 5) {
            //Dientes Temporales Cuandrante Derecho (Superior/Inferior)
            htmlLecheRight += '<div id="dienteindex' + i + '" style="left: -25%;" class="diente-leche">' +
                '<span class="label label-primary numeros">index' + i + '</span>' +
                '<div id="tindex' + i + '" class="cuadro-leche top-leche click">' +
                '</div>' +
                '<div id="lindex' + i + '" class="cuadro-leche izquierdo-leche click">' +
                '</div>' +
                '<div id="bindex' + i + '" class="cuadro-leche debajo-leche click">' +
                '</div>' +
                '<div id="rindex' + i + '" class="cuadro-leche derecha-leche click click">' +
                '</div>' +
                '<div id="cindex' + i + '" class="centro-leche click">' +
                '</div>' +
                '</div>';
        }
        if (a < 6) {
            //Dientes Temporales Cuandrante Izquierdo (Superior/Inferior)
            htmlLecheLeft += '<div id="dienteindex' + a + '" class="diente-leche">' +
                '<span class="label label-primary numeros">index' + a + '</span>' +
                '<div id="tindex' + a + '" class="cuadro-leche top-leche click">' +
                '</div>' +
                '<div id="lindex' + a + '" class="cuadro-leche izquierdo-leche click">' +
                '</div>' +
                '<div id="bindex' + a + '" class="cuadro-leche debajo-leche click">' +
                '</div>' +
                '<div id="rindex' + a + '" class="cuadro-leche derecha-leche click click">' +
                '</div>' +
                '<div id="cindex' + a + '" class="centro-leche click">' +
                '</div>' +
                '</div>';
        }
        a++;
    }
    $("#tr").append(replaceAll('index', '1', htmlRight));
    $("#tl").append(replaceAll('index', '2', htmlLeft));
    $("#tlr").append(replaceAll('index', '5', htmlLecheRight));
    $("#tll").append(replaceAll('index', '6', htmlLecheLeft));


    $("#bl").append(replaceAll('index', '3', htmlLeft));
    $("#br").append(replaceAll('index', '4', htmlRight));
    $("#bll").append(replaceAll('index', '7', htmlLecheLeft));
    $("#blr").append(replaceAll('index', '8', htmlLecheRight));
}

function iniciarOdontograma(datosdientes, tipo_dispositivo) {

    datosdientes.forEach(function(element) {

        var diente=element[1];
        var caries=diente[0].caries;
        caries.forEach(function(numerodiente) {
            if(numerodiente[1]!='null'){
                var dientePosition = $('#'+numerodiente[1]).position();
                var tipo='carie';
                var id=numerodiente[1];
                $('#'+numerodiente[1]).parent().append('<i id="carie_'+id+'" style="color:red;" class="circle fa-fw carie carie_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');

                if ($('#'+numerodiente[1]).hasClass("centro")) {
                    $('#'+numerodiente[1]).parent().children("i#carie_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1.1))-7,
                        "left": dientePosition.left+4,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("izquierdo")) {
                    $('#'+numerodiente[1]).parent().children("i#carie_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1.1))-7,
                        "left": dientePosition.left-2,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("derecha")) {
                    $('#'+numerodiente[1]).parent().children("i#carie_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1.1))-7,
                        "left": dientePosition.left,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("arriba")) {
                    $('#'+numerodiente[1]).parent().children("i#carie_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top-3)-7,
                        "left": dientePosition.left+4,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("debajo")) {
                    $('#'+numerodiente[1]).parent().children("i#carie_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1))-7,
                        "left": dientePosition.left+4,
                    });
                }

                //LECHE
                if ($('#'+numerodiente[1]).hasClass("centro-leche")) {
                    $('#'+numerodiente[1]).parent().children("i#carie_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1.1))-13,
                        "left": dientePosition.left+1,
                        "height" : 2,
                        "width" : 2,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("izquierdo-leche")) {
                    $('#'+numerodiente[1]).parent().children("i#carie_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1.1))-13,
                        "left": dientePosition.left,
                        "height" : 2,
                        "width" : 2,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("derecha-leche")) {
                    $('#'+numerodiente[1]).parent().children("i#carie_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1.1))-13,
                        "left": dientePosition.left+2,
                        "height" : 2,
                        "width" : 2,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("top-leche")) {
                    $('#'+numerodiente[1]).parent().children("i#carie_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top-3)-8,
                        "left": dientePosition.left+1,
                        "height" : 2,
                        "width" : 2,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("debajo-leche")) {
                    $('#'+numerodiente[1]).parent().children("i#carie_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1))-8,
                        "left": dientePosition.left+1,
                        "height" : 2,
                        "width" : 2,
                    });
                }

            }
        });

        // diente[0].infodiente [["t18","null"],["l18","null"],["b18","null"],["r18","null"],["c18","red"]]
        var infodiente=diente[1].infodiente;

        infodiente.forEach(function(numerodiente) {
            if(numerodiente[1]!='null'){
                if(numerodiente[1]!='corona'){
                    $('#'+numerodiente[0]).addClass("click-"+numerodiente[1]);
                }else{
                    $('#'+numerodiente[0]).addClass("click-blue");
                    $('#'+numerodiente[0]).addClass("corona");
                }

            }
        });

        // diente[1].puente ["c18","c11"]
        var puente=diente[2].puente;

        //console.log(puente);
        var arrayPuente = [];
        var arrayPuentedientes = [];
        puente.forEach(function(elementospuente) {
            if (arrayPuente.length < 1) {
                arrayPuente.push({
                    id:  $( "div#"+elementospuente).attr('id')
                });
                $( "div#"+elementospuente).parent().children('.cuadro').css('border-color', 'blue');
            } else {
                arrayPuente.push({
                    id:  $( "div#"+elementospuente).attr('id')
                });
                $( "div#"+elementospuente).parent().children('.cuadro').css('border-color', 'blue');
            }
            if(arrayPuente.length>1 && $( "div#"+elementospuente).attr('id')!=null){
                dibujarPuente(arrayPuente[0].id, arrayPuente[1].id, 'puente');
                arrayPuente = [];
                arrayPuentedientes = [];
            }
        });

        var sano=diente[3].sano[1];
        var id=$("div#"+sano).attr('id');
        var dientePosition = $("div#"+sano).position();
        $("div#"+sano).parent().children().each(function(index, el) {
            if ($(el).hasClass("centro")) {
                var tipo= 'sano';
                // $("div#"+sano).parent().append('<i id="sano_'+id+'" style="color:blue;" class="glyphicon glyphicon-ok fa-3x fa-fw sano sano_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                $("div#"+sano).parent().append('<i id="sano_'+id+'" style="color:blue;" class="paloma sano sano_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                if ($(el).hasClass("centro")) {
                    var dientePosition = $(el).position();
                    $(el).parent().children("i").css({
                        "position": "absolute",
                        // "top": dientePosition.top*(.79696),
                        // "left": dientePosition.left-15,
                        "top": dientePosition.top*(.35),
                        "left": dientePosition.left-10,
                    });
                }
            }

            if ( $(el).hasClass("centro-leche")) {
                var tipo= 'sano';
                //$("div#"+sano).parent().append('<i id="sano_'+id+'" style="color:blue;" class="glyphicon glyphicon-ok fa-3x fa-fw sano sano_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                $("div#"+sano).parent().append('<i id="sano_'+id+'" style="color:blue;" class="paloma sano sano_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                if ($(el).hasClass("centro")) {
                    var dientePosition = $(el).position();
                    $(el).parent().children("i").css({
                        "position": "absolute",
                        "top": dientePosition.top*(.79696),
                        "left": dientePosition.left-15,
                    });
                }else {

                    if(tipo_dispositivo == 0 ){
                        //$(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                        $(el).parent().children("i").css({"position": "absolute", "top": "5%", "left": "4.2ex"});
                    }else{
                        $(el).parent().children("i").css({"position": "absolute", "top": "2%", "left": "4ex"});
                        //$(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": ".6ex"});
                    }

                }
            }
        });

        var restoradicular=diente[4].restoradicular[1];
        var id=$("div#"+restoradicular).attr('id');
        var dientePosition = $("div#"+restoradicular).position();
        $("div#"+restoradicular).parent().children().each(function(index, el) {
            if ($(el).hasClass("centro")) {
                var tipo= 'restoradicular';
                $("div#"+restoradicular).parent().append('<i id="restoradicular_'+id+'" style="color:blue;" class="fa fa-RR fa-3x fa-fw restoradicular restoradicular_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                if ($(el).hasClass("centro")) {
                    var dientePosition = $(el).position();
                    $(el).parent().children("i").css({
                        "position": "absolute",
                        "top": dientePosition.top*(.79696),
                        "left": dientePosition.left-15,
                    });
                }
            }

            if ($(el).hasClass("centro-leche")) {
                var tipo= 'restoradicular';
                $("div#"+restoradicular).parent().append('<i id="restoradicular_'+id+'" style="color:blue;" class="fa fa-RR fa-3x fa-fw restoradicular restoradicular_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                if ($(el).hasClass("centro")) {
                    var dientePosition = $(el).position();
                    $(el).parent().children("i").css({
                        "position": "absolute",
                        "top": dientePosition.top*(.79696),
                        "left": dientePosition.left-15,
                    });
                } else {
                    if(tipo_dispositivo == 0 ){
                        $(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                    }else{
                        $(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                    }
                }
            }
        });

        var extraccionortodoncia=diente[5].extraccionortodoncia[1];
        var id=$("div#"+extraccionortodoncia).attr('id');
        var dientePosition = $("div#"+extraccionortodoncia).position();
        $("div#"+extraccionortodoncia).parent().children().each(function(index, el) {
            if ($(el).hasClass("centro") ) {

                var tipo= 'extracciono';
                //$("div#"+extraccionortodoncia).parent().append('<i id="extracciono_'+id+'" style="color:blue;" class="glyphicon glyphicon-remove fa-3x fa-fw extracciono extracciono_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                $("div#"+extraccionortodoncia).parent().append('<i id="extracciono_'+id+'" style="color:blue;" class="cruz extracciono extracciono_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                if ($(el).hasClass("centro")) {
                    var dientePosition = $(el).position();
                    $(el).parent().children("i").css({
                        "position": "absolute",
                        // "top": dientePosition.top*(.79696),
                        // "left": dientePosition.left-15,
                        "top": dientePosition.top*(.35),
                        "left": dientePosition.left-10,
                    });
                }
            }

            if ($(el).hasClass("centro-leche")) {
                var tipo= 'extracciono';
                //$("div#"+extraccionortodoncia).parent().append('<i id="extracciono_'+id+'" style="color:blue;" class="glyphicon glyphicon-remove fa-3x fa-fw extracciono extracciono_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                $("div#"+extraccionortodoncia).parent().append('<i id="extracciono_'+id+'" style="color:blue;" class="cruz extracciono extracciono_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                if ($(el).hasClass("centro")) {
                    var dientePosition = $(el).position();
                    $(el).parent().children("i").css({
                        "position": "absolute",
                        "top": dientePosition.top*(.79696),
                        "left": dientePosition.left-15,
                    });
                }else {
                    if(tipo_dispositivo == 0 ){
                        //$(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                        $(el).parent().children("i").css({"position": "absolute", "top": "5%", "left": "4.2ex"});
                    }else{
                        $(el).parent().children("i").css({"position": "absolute", "top": "2%", "left": "4ex"});
                        //$(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": ".6ex"});
                    }
                }
            }

        });

        var fractura=diente[6].fractura[1];
        var id=$("div#"+fractura).attr('id');
        var dientePosition = $("div#"+fractura).position();
        $("div#"+fractura).parent().children().each(function(index, el) {
            if ($(el).hasClass("centro")) {
                var tipo= 'fractura';
                $("div#"+fractura).parent().append('<i id="fractura_'+id+'" style="color:red;" class="diagonal fa-3x fa-fw fractura fractura_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                if ($(el).hasClass("centro")) {
                    var dientePosition = $(el).position();
                    if(tipo_dispositivo == 0 ){
                        $(el).parent().children("i").css({
                            "z-index": 1,
                            "position": "absolute",
                            "top":  dientePosition.top*((-1)*(.69696))-9,//dientePosition.top*(.79696),
                            "left": dientePosition.left-15,
                        });
                    }else{
                        $(el).parent().children("i").css({
                            "z-index": 1,
                            "position": "absolute",
                            "top":  dientePosition.top*((-1)*(.1)),//dientePosition.top*(.79696),
                            "left": dientePosition.left+4,
                            "font-size": "1.5rem",
                        });
                    }
                }
            }

            if ($(el).hasClass("centro-leche")) {
                var tipo= 'fractura';
                $("div#"+fractura).parent().append('<i id="fractura_'+id+'" style="color:red;" class="diagonal fa-3x fa-fw fractura fractura_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                if ($(el).hasClass("centro")) {
                    var dientePosition = $(el).position();
                    $(el).parent().children("i").css({
                        "z-index": 1,
                        "position": "absolute",
                        "top": dientePosition.top*((-1)*(.69696))-9,//dientePosition.top*(.79696),
                        "left": dientePosition.left-15,
                    });
                }else {
                    if(tipo_dispositivo == 0 ){
                        $(el).parent().children("i").css({"z-index": 1, "position": "absolute", "top": "-46%", "left": "1.2ex"});
                    }else{
                        $(el).parent().children("i").css({"z-index": 1, "position": "absolute", "top": "-15%", "left": "3.7ex", "font-size": "1.5rem"});
                    }
                }
            }

        });

        var semierupcionado=diente[7].semierupcionado[1];
        var id=$("div#"+semierupcionado).attr('id');
        var dientePosition = $("div#"+semierupcionado).position();
        $("div#"+semierupcionado).parent().children().each(function(index, el) {
            if ($(el).hasClass("centro")) {
                var tipo= 'semierupcionado';
                $("div#"+semierupcionado).parent().append('<i id="semierupcionado_'+id+'" style="color:blue;" class="diagonal fa-3x fa-fw semierupcionado semierupcionado_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                if ($(el).hasClass("centro")) {
                    var dientePosition = $(el).position();

                    if(tipo_dispositivo == 0 ){
                        $(el).parent().children("i").css({
                            "z-index": 1,
                            "position": "absolute",
                            "top":  dientePosition.top*((-1)*(.69696))-9,//dientePosition.top*(.79696),
                            "left": dientePosition.left-15,
                        });
                    }else{
                        $(el).parent().children("i").css({
                            "z-index": 1,
                            "position": "absolute",
                            "top":  dientePosition.top*((-1)*(.1)),//dientePosition.top*(.79696),
                            "left": dientePosition.left+4,
                            "font-size": "1.5rem",
                        });
                    }

                }
            }

            if ($(el).hasClass("centro-leche")) {
                var tipo= 'semierupcionado';
                $("div#"+semierupcionado).parent().append('<i id="semierupcionado_'+id+'" style="color:blue;" class="diagonal fa-3x fa-fw semierupcionado semierupcionado_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                if ($(el).hasClass("centro")) {
                    var dientePosition = $(el).position();
                    $(el).parent().children("i").css({
                        "z-index": 1,
                        "position": "absolute",
                        "top":  dientePosition.top*((-1)*(.69696))-9,//dientePosition.top*(.79696),
                        "left": dientePosition.left-15,
                    });
                }else {
                    if(tipo_dispositivo == 0 ){
                        $(el).parent().children("i").css({ "z-index": 1, "position": "absolute", "top": "-46%", "left": "1.2ex"});
                    }else{
                        $(el).parent().children("i").css({ "z-index": 1, "position": "absolute", "top": "-15%", "left": "3.7ex", "font-size": "1.5rem"});
                    }
                }
            }
        });

        // diente[2].extraccion[1]  "c44"
        var extraccion=diente[8].extraccionp[1];
        var id=$("div#"+extraccion).attr('id');
        var dientePosition = $("div#"+extraccion).position();
        $("div#"+extraccion).parent().children().each(function(index, el) {
            if ($(el).hasClass("centro")) {
                var tipo='extraccionp';
                //$("div#"+extraccion).parent().append('<i id="extraccionp_'+id+'" style="color:red;" class="glyphicon glyphicon-remove fa-3x fa-fw extraccionp extraccionp_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                $("div#"+extraccion).parent().append('<i id="extraccionp_'+id+'" style="color:red;" class="cruz extraccionp extraccionp_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                if ($(el).hasClass("centro")) {
                    var dientePosition = $(el).position();
                    $(el).parent().children("i").css({
                        "position": "absolute",
                        // "top": dientePosition.top*(.79696),
                        // "left": dientePosition.left-15,
                        "top": dientePosition.top*(.35),
                        "left": dientePosition.left-10,
                    });
                }
            }

            if ( $(el).hasClass("centro-leche")) {
                var tipo='extraccionp';
                //$("div#"+extraccion).parent().append('<i id="extraccionp_'+id+'" style="color:red;" class="glyphicon glyphicon-remove fa-3x fa-fw extraccionp extraccionp_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                $("div#"+extraccion).parent().append('<i id="extraccionp_'+id+'" style="color:red;" class="cruz extraccionp extraccionp_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                if ($(el).hasClass("centro")) {
                    var dientePosition = $(el).position();
                    $(el).parent().children("i").css({
                        "position": "absolute",
                        "top": dientePosition.top*(.79696),
                        "left": dientePosition.left-15,
                    });
                }else {
                    if(tipo_dispositivo == 0 ){
                        //$(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                        $(el).parent().children("i").css({"position": "absolute", "top": "5%", "left": "4.2ex"});
                    }else{
                        $(el).parent().children("i").css({"position": "absolute", "top": "2%", "left": "4ex"});
                        //$(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": ".6ex"});
                    }
                }
            }
        });

        var obturacionmal=diente[9].obturacionmal;
        obturacionmal.forEach(function(numerodiente) {
            if(numerodiente[1]!='null'){
                var dientePosition = $('#'+numerodiente[1]).position();
                var tipo='obturacionmal';
                var id=numerodiente[1];
                $('#'+numerodiente[1]).parent().append('<i id="obturacionmal_'+id+'" style="color:blue;" class="circle fa-fw obturacionmal obturacionmal_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                $('#'+numerodiente[1]).parent().children('.cuadro').css('border-color', 'red');
                $('#'+numerodiente[1]).parent().children('.cuadro-leche').css('border-color', 'red');

                if ($('#'+numerodiente[1]).hasClass("centro")) {
                    $('#'+numerodiente[1]).parent().children("i#obturacionmal_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1.1))-7,
                        "left": dientePosition.left+4,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("izquierdo")) {
                    $('#'+numerodiente[1]).parent().children("i#obturacionmal_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1.1))-7,
                        "left": dientePosition.left-2,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("derecha")) {
                    $('#'+numerodiente[1]).parent().children("i#obturacionmal_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1.1))-7,
                        "left": dientePosition.left,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("arriba")) {
                    $('#'+numerodiente[1]).parent().children("i#obturacionmal_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top-3)-7,
                        "left": dientePosition.left+4,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("debajo")) {
                    $('#'+numerodiente[1]).parent().children("i#obturacionmal_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1))-7,
                        "left": dientePosition.left+4,
                    });
                }

                //LECHE
                if ($('#'+numerodiente[1]).hasClass("centro-leche")) {
                    $('#'+numerodiente[1]).parent().children("i#obturacionmal_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1.1))-13,
                        "left": dientePosition.left+1,
                        "height" : 2,
                        "width" : 2,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("izquierdo-leche")) {
                    $('#'+numerodiente[1]).parent().children("i#obturacionmal_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1.1))-13,
                        "left": dientePosition.left,
                        "height" : 2,
                        "width" : 2,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("derecha-leche")) {
                    $('#'+numerodiente[1]).parent().children("i#obturacionmal_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1.1))-13,
                        "left": dientePosition.left+2,
                        "height" : 2,
                        "width" : 2,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("top-leche")) {
                    $('#'+numerodiente[1]).parent().children("i#obturacionmal_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top-3)-8,
                        "left": dientePosition.left+1,
                        "height" : 2,
                        "width" : 2,
                    });
                }
                if ($('#'+numerodiente[1]).hasClass("debajo-leche")) {
                    $('#'+numerodiente[1]).parent().children("i#obturacionmal_"+id).css({
                        "position": "absolute",
                        "top": (dientePosition.top*(1))-8,
                        "left": dientePosition.left+1,
                        "height" : 2,
                        "width" : 2,
                    });
                }

            }
        });

        var puentem=diente[10].puentem;
        var arrayPuente = [];
        var arrayPuentedientes = [];
        puentem.forEach(function(elementospuente) {
            if (arrayPuente.length < 1) {
                arrayPuente.push({
                    id:  $( "div#"+elementospuente).attr('id')
                });
                $( "div#"+elementospuente).parent().children('.cuadro').css('border-color', 'red');
            } else {
                arrayPuente.push({
                    id:  $( "div#"+elementospuente).attr('id')
                });
                $( "div#"+elementospuente).parent().children('.cuadro').css('border-color', 'red');
            }
            if(arrayPuente.length>1 && $( "div#"+elementospuente).attr('id')!=null){
                dibujarPuente(arrayPuente[0].id, arrayPuente[1].id, 'puentem');
                arrayPuente = [];
                arrayPuentedientes = [];
            }
        });

        var ppr=diente[11].ppr;
        var arrayPuente = [];
        var arrayPuentedientes = [];
        ppr.forEach(function(elementospuente) {
            if (arrayPuente.length < 1) {
                arrayPuente.push({
                    id:  $( "div#"+elementospuente).attr('id')
                });
                $( "div#"+elementospuente).parent().children('.cuadro').css('border-color', 'blue');
            } else {
                arrayPuente.push({
                    id:  $( "div#"+elementospuente).attr('id')
                });
                $( "div#"+elementospuente).parent().children('.cuadro').css('border-color', 'blue');
            }
            if(arrayPuente.length>1 && $( "div#"+elementospuente).attr('id')!=null){
                dibujarPuente(arrayPuente[0].id, arrayPuente[1].id, 'ppr');
                arrayPuente = [];
                arrayPuentedientes = [];
            }
        });

        if(diente[12]!=null){

            var desgaste=diente[12].des[1];
            var id=$("div#"+desgaste).attr('id');
            var dientePosition = $("div#"+desgaste).position();
            $("div#"+desgaste).parent().children().each(function(index, el) {
                if ($(el).hasClass("centro")) {
                    var tipo= 'desgaste';
                    $("div#"+desgaste).parent().append('<i id="desgaste_'+id+'" style="color:blue;" class="fa fa-DESGASTE-icono fa-3x fa-fw desgaste desgaste_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                    if ($(el).hasClass("centro")) {
                        var dientePosition = $(el).position();
                        $(el).parent().children("i").css({
                            "position": "absolute",
                            "top": dientePosition.top*(.79696),
                            "left": dientePosition.left-15,
                        });
                    }
                }

                if ($(el).hasClass("centro-leche")) {
                    var tipo= 'desgaste';
                    $("div#"+desgaste).parent().append('<i id="desgaste_'+id+'" style="color:blue;" class="fa fa-DESGASTE-icono fa-3x fa-fw desgaste desgaste_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                    if ($(el).hasClass("centro")) {
                        var dientePosition = $(el).position();
                        $(el).parent().children("i").css({
                            "position": "absolute",
                            "top": dientePosition.top*(.79696),
                            "left": dientePosition.left-15,
                        });
                    } else {
                        if(tipo_dispositivo == 0 ){
                            $(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                        }else{
                            $(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1ex"});
                        }
                    }
                }
            });

        }

        if(diente[13]!=null){

            var movil=diente[13].mvl[1];
            var id=$("div#"+movil).attr('id');
            var dientePosition = $("div#"+movil).position();
            $("div#"+movil).parent().children().each(function(index, el) {
                if ($(el).hasClass("centro")) {
                    var tipo= 'movil';
                    $("div#"+movil).parent().append('<i id="movil_'+id+'" style="color:blue;" class="fa fa-MOVIL-icono fa-3x fa-fw movil movil_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                    if ($(el).hasClass("centro")) {
                        var dientePosition = $(el).position();
                        $(el).parent().children("i").css({
                            "position": "absolute",
                            "top": dientePosition.top*(.79696),
                            "left": dientePosition.left-15,
                        });
                    }
                }

                if ($(el).hasClass("centro-leche")) {
                    var tipo= 'movil';
                    $("div#"+movil).parent().append('<i id="movil_'+id+'" style="color:blue;" class="fa fa-MOVIL-icono fa-3x fa-fw movil movil_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                    if ($(el).hasClass("centro")) {
                        var dientePosition = $(el).position();
                        $(el).parent().children("i").css({
                            "position": "absolute",
                            "top": dientePosition.top*(.79696),
                            "left": dientePosition.left-15,
                        });
                    } else {
                        if(tipo_dispositivo == 0 ){
                            $(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                        }else{
                            $(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.1ex"});
                        }
                    }
                }
            });

        }

        if(diente[14]!=null){
            var endo=diente[14].endo[1];
            var id=$("div#"+endo).attr('id');
            var dientePosition = $("div#"+endo).position();
            $("div#"+endo).parent().children().each(function(index, el) {
                if ($(el).hasClass("centro")) {
                    var tipo= 'endo';
                    $("div#"+endo).parent().append('<i id="endo_'+id+'" style="color:green;" class="fa fa-ENDO-icono fa-3x fa-fw endo endo_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                    if ($(el).hasClass("centro")) {
                        var dientePosition = $(el).position();
                        $(el).parent().children("i").css({
                            "position": "absolute",
                            "top": dientePosition.top*(.79696),
                            "left": dientePosition.left-15,
                        });
                    }
                }

                if ($(el).hasClass("centro-leche")) {
                    var tipo= 'endo';
                    $("div#"+endo).parent().append('<i id="endo_'+id+'" style="color:green;" class="fa fa-ENDO-icono fa-3x fa-fw endo endo_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                    if ($(el).hasClass("centro")) {
                        var dientePosition = $(el).position();
                        $(el).parent().children("i").css({
                            "position": "absolute",
                            "top": dientePosition.top*(.79696),
                            "left": dientePosition.left-15,
                        });
                    } else {
                        if(tipo_dispositivo == 0 ){
                            $(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                        }else{
                            $(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.1ex"});
                        }
                    }
                }
            });

        }

        if(diente[15]!=null){

            var implante=diente[15].implante[1];
            var id=$("div#"+implante).attr('id');
            var dientePosition = $("div#"+implante).position();
            $("div#"+implante).parent().children().each(function(index, el) {
                if ($(el).hasClass("centro")) {
                    var tipo= 'implante';
                    $("div#"+implante).parent().append('<i id="implante_'+id+'" style="color:green;" class="fa fa-IMPLANTE-icono fa-3x fa-fw implante implante_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                    if ($(el).hasClass("centro")) {
                        var dientePosition = $(el).position();
                        $(el).parent().children("i").css({
                            "position": "absolute",
                            "top": dientePosition.top*(.79696),
                            "left": dientePosition.left-15,
                        });
                    }
                }

                if ($(el).hasClass("centro-leche")) {
                    var tipo= 'implante';
                    $("div#"+implante).parent().append('<i id="implante_'+id+'" style="color:green;" class="fa fa-IMPLANTE-icono fa-3x fa-fw implante implante_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                    if ($(el).hasClass("centro")) {
                        var dientePosition = $(el).position();
                        $(el).parent().children("i").css({
                            "position": "absolute",
                            "top": dientePosition.top*(.79696),
                            "left": dientePosition.left-15,
                        });
                    } else {
                        if(tipo_dispositivo == 0 ){
                            $(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                        }else{
                            $(el).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.1ex"});
                        }
                    }
                }
            });

        }


        // extraccion.forEach(function(numerodiente) {
        //     //$('#'+numerodiente[0]).addClass("click-"+numerodiente[1]);

        // });
    });
}

function dibujarPuente(diente1, diente2, tipo){

    var color_puente=null;
    var tipo_puente=null;

    switch (tipo){
        case 'puente':
            color_puente='blue';
            tipo_puente='puente';
            break;
        case 'puentem':
            color_puente='red';
            tipo_puente='puentem';
           break;
        case 'ppr':
            color_puente='blue';
            tipo_puente='ppr';
            break;
    }

    arrayPuente = [];
    arrayPuentedientes = [];

    var dientePosition = $('div#'+diente1).offset(), leftPX;
    var left = 0,
        width = 0;

    //primer diente
    arrayPuentedientes.push({diente: $('div#'+diente1).parent().children('.centro').attr('id')});
    arrayPuente.push({
        diente: $('div#'+diente1).parent().children().first().text(),
        cuadrante: $('div#'+diente1).parent().parent().attr('id'),
        left: $('div#'+diente1).parent().children('.centro')[0].offsetLeft,
        father: null,
        id: $('div#'+diente1).attr('id')
    });

    //segundo diente
    arrayPuentedientes.push({diente: $('div#'+diente2).parent().children('.centro').attr('id')});
    arrayPuente.push({
        diente: $('div#'+diente2).parent().children().first().text(),
        cuadrante: $('div#'+diente2).parent().parent().attr('id'),
        left: $('div#'+diente2).parent().children('.centro')[0].offsetLeft,
        father: arrayPuente[0].diente,
        id: $('div#'+diente2).attr('id')
    });

    //dibujado de linea
    var diferencia = Math.abs((parseInt(arrayPuente[1].diente) - parseInt(arrayPuente[1].father)));
    if (diferencia == 1) width = diferencia * 60;
    else width = diferencia * 50;

    if(arrayPuente[0].cuadrante == arrayPuente[1].cuadrante) {
        if(arrayPuente[0].cuadrante == 'tr' || arrayPuente[0].cuadrante == 'tlr' || arrayPuente[0].cuadrante == 'br' || arrayPuente[0].cuadrante == 'blr') {
            if (arrayPuente[0].diente > arrayPuente[1].diente) {
                leftPX = (parseInt(arrayPuente[0].left)+10)+"px";
            }else {
                leftPX = (parseInt(arrayPuente[1].left)+10)+"px";
            }
        }else {
            if (arrayPuente[0].diente < arrayPuente[1].diente) {
                leftPX = (parseInt(arrayPuente[0].left)+10)+"px";
            }else {
                leftPX = (parseInt(arrayPuente[1].left)+10)+"px";
            }
        }
        if(leftPX=='57px'){
            leftPX='-45px'
        }
        $('div#'+diente2).parent().append('<div id="'+arrayPuentedientes[0].diente+'_'+arrayPuentedientes[1].diente+'" style="z-index: 999; height: 5px; width:' + width + 'px;"  class="'+tipo_puente+' click-'+color_puente+' '+tipo_puente+'_'+arrayPuentedientes[0].diente+' " onclick="eliminarPuente('+arrayPuentedientes[0].diente+', '+arrayPuentedientes[1].diente+', '+tipo_puente+');"></div>');
        $('div#'+diente2).parent().children().last().css({
            "position": "absolute",
            "top": "80px",
            "left": leftPX
        });

    }else{

        var localizacion=null;
        var diferencia=null;
        var diente=null;

        if(arrayPuente[0].cuadrante == 'bl' || arrayPuente[0].cuadrante == 'tl'){
            localizacion=arrayPuente[0].id;
            diferencia = Math.abs((parseInt(arrayPuente[1].diente) - parseInt(arrayPuente[1].father)));
            diente=parseInt(arrayPuente[1].father);
            leftPX=(-570)+(parseInt(arrayPuente[1].left)+10);
        }else{
            localizacion=arrayPuente[1].id;
            diferencia = Math.abs((parseInt(arrayPuente[1].diente) - parseInt(arrayPuente[1].father)));
            diente=parseInt(arrayPuente[1].diente);
            leftPX=(-570)+(parseInt(arrayPuente[0].left)+10);
        }

        if(arrayPuente[0].cuadrante == 'tl' || arrayPuente[1].cuadrante == 'tl'){
            if(diente<=28 && diente>=21){
                diente=parseInt(arrayPuente[1].father);
                if(diente<=28 && diente>=21){
                    diente=parseInt(arrayPuente[1].diente);
                }
            }
        }

        var numCuadrante=parseInt((diente.toString()).charAt(0))*10;

        if (diferencia >= 10 && diente==(numCuadrante+1) ){ width = (diferencia * 50)-275;}
        if (diferencia >= 9 && diente==(numCuadrante+2) ){ width = (diferencia * 50)-175;}
        if (diferencia >= 8 && diente==(numCuadrante+3) ){ width = (diferencia * 50)-75;}
        if (diferencia >= 7 && diente==(numCuadrante+4) ){ width = (diferencia * 50)+25;}
        if (diferencia <= 13 && diente==(numCuadrante+5) ){ width = (diferencia * 50)+125;}
        if (diferencia <= 12 && diente==(numCuadrante+6) ){ width = (diferencia * 50)+225;}
        if (diferencia <= 11 && diente==(numCuadrante+7) ){ width = (diferencia * 50)+325;}
        if (diferencia <= 10 && diente==(numCuadrante+8) ){ width = (diferencia * 50)+425;}

        $("div#"+localizacion).parent().append('<div id="'+arrayPuentedientes[0].diente+'_'+arrayPuentedientes[1].diente+'" style="z-index: 999; height: 5px; width:' + width + 'px;"  class="'+tipo_puente+' click-'+color_puente+' '+tipo_puente+'_'+arrayPuentedientes[0].diente+' " onclick="eliminarPuente('+arrayPuentedientes[0].diente+', '+arrayPuentedientes[1].diente+', '+tipo_puente+');"></div>');
        $("div#"+localizacion).parent().children().last().css({
            "position": "absolute",
            "top": "80px",
            "left": leftPX
        });
    }

    arrayPuente = [];
    arrayPuentedientes = [];

}

function cambiarColor(valor, color) {
    if (valor.hasClass("click-clear")) {valor.removeClass('click-clear');}
    if (valor.hasClass("click-blue")) {valor.removeClass('click-blue');}
    if (valor.hasClass("click-red")) {valor.removeClass('click-red');}
    if (valor.hasClass("click-delete")) {
        valor.parent().children().each(function(index, el) {
            if ($(el).hasClass("click")) {
                $(el).removeClass('click-delete');
            }
        });
    }
    if (valor.hasClass("corona")) {
        valor.parent().children().each(function(index, el) {
            if ($(el).hasClass("click")) {
                $(el).removeClass('corona');
                $(el).removeClass('click-blue');
            }
        });
    }
    if(color!='click-delete'){
        if(color!='click-clear'){
            valor.addClass(color);
        }
    }else{
        valor.parent().children().each(function(index, el) {
            if ($(el).hasClass("click-clear")) {$(el).removeClass('click-clear');}
            if ($(el).hasClass("click-blue")) {$(el).removeClass('click-blue');}
            if ($(el).hasClass("click-red")) {$(el).removeClass('click-red');}
            if ($(el).hasClass("click")) {
                $(el).addClass('click-delete');
            }
        });
    }
}

function eliminarPuente(id1, id2, tipo) {
    var control = $("#controls").children().find('.active').attr('id');
    switch (control) {
        case "borrar":
            $('.'+tipo.id+'_'+id1.id).remove();
            $( "div#"+id1.id).parent().children('.cuadro').removeAttr("style");
            $( "div#"+id2.id).parent().children('.cuadro').removeAttr("style");
            break;
    }
}

function eliminarObjeto(id, tipo) {
    var control = $("#controls").children().find('.active').attr('id');
    switch (control) {
        case "borrar":
            if(tipo.id=='obturacionmal'){ $('i#obturacionmal_'+id.id).parent().children('.cuadro').removeAttr("style"); $('i#obturacionmal_'+id.id).parent().children('.cuadro-leche').removeAttr("style");}
            $('.'+tipo.id+'_'+id.id).remove();
            break;
    }
}

var arrayPuente = [];
var arrayPuentedientes = [];
function iniciarBotones(tipo_dispositivo) {
  $(".click").click(function(event) {
      var control = $("#controls").children().find('.active').attr('id');
      var cuadro = $(this).find("input[name=cuadro]:hidden").val();
      //console.log($(this).attr('id'))
      var id=$(this).attr('id');
      switch (control) {
          case "carieamplia":
              cambiarColor($(this), 'click-red');
              break;
          case "obturacion":
              cambiarColor($(this), 'click-blue');
              break;
          case "obturacionmal":
              var dientePosition = $(this).position();
              var tipo='obturacionmal';
              //console.log(dientePosition);
              $(this).parent().append('<i id="obturacionmal_'+id+'" style="color:blue;" class="circle fa-fw obturacionmal obturacionmal_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
              $(this).parent().children('.cuadro-leche').css('border-color', 'red');
              $(this).parent().children('.cuadro').css('border-color', 'red');

              if ($(this).hasClass("centro")) {
                  $(this).parent().children("i#obturacionmal_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1.1))-7,
                      "left": dientePosition.left+4,
                  });
              }
              if ($(this).hasClass("izquierdo")) {
                  $(this).parent().children("i#obturacionmal_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1.1))-7,
                      "left": dientePosition.left-2,
                  });
              }
              if ($(this).hasClass("derecha")) {
                  $(this).parent().children("i#obturacionmal_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1.1))-7,
                      "left": dientePosition.left,
                  });
              }
              if ($(this).hasClass("arriba")) {
                  $(this).parent().children("i#obturacionmal_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top-3)-7,
                      "left": dientePosition.left+4,
                  });
              }
              if ($(this).hasClass("debajo")) {
                  $(this).parent().children("i#obturacionmal_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1))-7,
                      "left": dientePosition.left+4,
                  });
              }

              //LECHE
              if ($(this).hasClass("centro-leche")) {
                  $(this).parent().children("i#obturacionmal_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1.1))-13,
                      "left": dientePosition.left+1,
                      "height" : 2,
                      "width" : 2,
                  });
                  break;
              }
              if ($(this).hasClass("izquierdo-leche")) {
                  $(this).parent().children("i#obturacionmal_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1.1))-13,
                      "left": dientePosition.left,
                      "height" : 2,
                      "width" : 2,
                  });
                  break;
              }
              if ($(this).hasClass("derecha-leche")) {
                  $(this).parent().children("i#obturacionmal_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1.1))-13,
                      "left": dientePosition.left+2,
                      "height" : 2,
                      "width" : 2,
                  });
                  break;
              }
              if ($(this).hasClass("top-leche")) {
                  $(this).parent().children("i#obturacionmal_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top-3)-8,
                      "left": dientePosition.left+1,
                      "height" : 2,
                      "width" : 2,
                  });
                  break;
              }
              if ($(this).hasClass("debajo-leche")) {
                  $(this).parent().children("i#obturacionmal_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1))-8,
                      "left": dientePosition.left+1,
                      "height" : 2,
                      "width" : 2,
                  });
                  break;
              }




              break;
          case "noerupcionado":
              cambiarColor($(this), 'click-delete');
              break;
          case "corona":
              //cambiarColor($(this), 'click-blue');
              $(this).parent().children().each(function(index, el) {
                  if ($(el).hasClass("click-clear")) {$(el).removeClass('click-clear');}
                  if ($(el).hasClass("click-delete")) {$(el).removeClass('click-delete');}
                  if ($(el).hasClass("click-red")) {$(el).removeClass('click-red');}
                  if ($(el).hasClass("click")) {
                      $(el).addClass('click-blue');
                      $(el).addClass('corona');
                  }
              });
              break;
          case "carie":
              var dientePosition = $(this).position();
              var tipo='carie';
              //console.log(dientePosition);
              $(this).parent().append('<i id="carie_'+id+'" style="color:red;" class="circle fa-fw carie carie_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');

              if ($(this).hasClass("centro")) {
                  $(this).parent().children("i#carie_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1.1))-7,
                      "left": dientePosition.left+4,

                  });
                  break;
              }
              if ($(this).hasClass("izquierdo")) {
                  $(this).parent().children("i#carie_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1.1))-7,
                      "left": dientePosition.left-2,
                  });
                  break;
              }
              if ($(this).hasClass("derecha")) {
                  $(this).parent().children("i#carie_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1.1))-7,
                      "left": dientePosition.left,
                  });
                  break;
              }
              if ($(this).hasClass("arriba")) {
                  $(this).parent().children("i#carie_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top-3)-7,
                      "left": dientePosition.left+4,
                  });
                  break;
              }
              if ($(this).hasClass("debajo")) {
                  $(this).parent().children("i#carie_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1))-7,
                      "left": dientePosition.left+4,
                  });
                  break;
              }
              //LECHE
              if ($(this).hasClass("centro-leche")) {
                  $(this).parent().children("i#carie_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1.1))-13,
                      "left": dientePosition.left+1,
                      "height" : 2,
                      "width" : 2,
                  });
                  break;
              }
              if ($(this).hasClass("izquierdo-leche")) {
                  $(this).parent().children("i#carie_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1.1))-13,
                      "left": dientePosition.left,
                      "height" : 2,
                      "width" : 2,
                  });
                  break;
              }
              if ($(this).hasClass("derecha-leche")) {
                  $(this).parent().children("i#carie_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1.1))-13,
                      "left": dientePosition.left+2,
                      "height" : 2,
                      "width" : 2,
                  });
                  break;
              }
              if ($(this).hasClass("top-leche")) {
                  $(this).parent().children("i#carie_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top-3)-8,
                      "left": dientePosition.left+1,
                      "height" : 2,
                      "width" : 2,
                  });
                  break;
              }
              if ($(this).hasClass("debajo-leche")) {
                  $(this).parent().children("i#carie_"+id).css({
                      "position": "absolute",
                      "top": (dientePosition.top*(1))-8,
                      "left": dientePosition.left+1,
                      "height" : 2,
                      "width" : 2,
                  });
                  break;
              }

              break;
          case "sano":
              $(this).parent().children().each(function(index, el) {
                  if ($(el).hasClass("centro") || $(el).hasClass("centro-leche")) {
                      var tipo= 'sano';
                      //$(this).parent().append('<i id="sano_'+id+'" style="color:blue;" class="glyphicon glyphicon-ok fa-3x fa-fw sano sano_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                      $(this).parent().append('<i id="sano_'+id+'" style="color:blue;" class="paloma sano sano_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                      if ($(el).hasClass("centro")) {
                          var dientePosition = $(el).position();
                          $(el).parent().children("i").css({
                              "position": "absolute",
                              // "top": dientePosition.top*(.79696),
                              // "left": dientePosition.left-15,
                              "top": dientePosition.top*(.35),
                              "left": dientePosition.left-10,
                          });
                      } else {

                          if(tipo_dispositivo == 0 ){
                              //$(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                              $(this).parent().children("i").css({"position": "absolute", "top": "5%", "left": "4.2ex"});
                          }else{
                              $(this).parent().children("i").css({"position": "absolute", "top": "2%", "left": "4ex"});
                              //$(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": ".6ex"});
                          }
                      }
                  }
              });
              break;
          case "restoradicular":
              $(this).parent().children().each(function(index, el) {
                  if ($(el).hasClass("centro") || $(el).hasClass("centro-leche")) {
                      var tipo= 'restoradicular';
                      $(this).parent().append('<i id="restoradicular_'+id+'" style="color:blue;" class="fa fa-RR fa-3x fa-fw restoradicular restoradicular_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                      if ($(el).hasClass("centro")) {
                          var dientePosition = $(el).position();
                          $(el).parent().children("i").css({
                              "position": "absolute",
                              "top": dientePosition.top*(.79696),
                              "left": dientePosition.left-15,
                          });
                      } else {
                          if(tipo_dispositivo == 0 ){
                              $(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                          }else{
                              $(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                          }
                      }
                  }
              });
              break;
          case "movil":
                  $(this).parent().children().each(function(index, el) {
                      if ($(el).hasClass("centro") || $(el).hasClass("centro-leche")) {
                          var tipo= 'movil';
                          $(this).parent().append('<i id="movil_'+id+'" style="color:blue;" class="fa fa-MOVIL-icono fa-3x fa-fw movil movil_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                          if ($(el).hasClass("centro")) {
                              var dientePosition = $(el).position();
                              $(el).parent().children("i").css({
                                  "position": "absolute",
                                  "top": dientePosition.top*(.79696),
                                  "left": dientePosition.left-15,
                              });
                          } else {
                              if(tipo_dispositivo == 0 ){
                                  $(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                              }else{
                                  $(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.1ex"});
                              }
                          }
                      }
                  });
                  break;
          case "desgaste":
              $(this).parent().children().each(function(index, el) {
                  if ($(el).hasClass("centro") || $(el).hasClass("centro-leche")) {
                      var tipo= 'desgaste';
                      $(this).parent().append('<i id="desgaste_'+id+'" style="color:blue;" class="fa fa-DESGASTE-icono fa-3x fa-fw desgaste desgaste_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                      if ($(el).hasClass("centro")) {
                          var dientePosition = $(el).position();
                          $(el).parent().children("i").css({
                              "position": "absolute",
                              "top": dientePosition.top*(.79696),
                              "left": dientePosition.left-15,
                          });
                      } else {
                          if(tipo_dispositivo == 0 ){
                              $(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                          }else{
                              $(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1ex"});
                          }
                      }
                  }
              });
              break;
          case "endo":
              $(this).parent().children().each(function(index, el) {
                  if ($(el).hasClass("centro") || $(el).hasClass("centro-leche")) {
                      var tipo= 'endo';
                      $(this).parent().append('<i id="endo_'+id+'" style="color:blue;" class="fa fa-ENDO-icono fa-3x fa-fw endo endo_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                      if ($(el).hasClass("centro")) {
                          var dientePosition = $(el).position();
                          $(el).parent().children("i").css({
                              "position": "absolute",
                              "top": dientePosition.top*(.79696),
                              "left": dientePosition.left-15,
                          });
                      } else {
                          if(tipo_dispositivo == 0 ){
                              $(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                          }else{
                              $(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1ex"});
                          }
                      }
                  }
              });
              break;
          case "implante":
              $(this).parent().children().each(function(index, el) {
                  if ($(el).hasClass("centro") || $(el).hasClass("centro-leche")) {
                      var tipo= 'implante';
                      $(this).parent().append('<i id="implante_'+id+'" style="color:blue;" class="fa fa-IMPLANTE-icono fa-3x fa-fw implante implante_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                      if ($(el).hasClass("centro")) {
                          var dientePosition = $(el).position();
                          $(el).parent().children("i").css({
                              "position": "absolute",
                              "top": dientePosition.top*(.79696),
                              "left": dientePosition.left-15,
                          });
                      } else {
                          if(tipo_dispositivo == 0 ){
                              $(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                          }else{
                              $(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1ex"});
                          }
                      }
                  }
              });
              break;
          case "extracciono":
              $(this).parent().children().each(function(index, el) {
                  if ($(el).hasClass("centro") || $(el).hasClass("centro-leche")) {
                      var tipo= 'extracciono';
                      //$(this).parent().append('<i id="extracciono_'+id+'" style="color:blue;" class="glyphicon glyphicon-remove fa-3x fa-fw extracciono extracciono_'+id+'" onclick="eliminarObjeto('+id+','+tipo+');"></i>');
                      $(this).parent().append('<i id="extracciono_'+id+'" style="color:blue;" class="cruz extracciono extracciono_'+id+'" onclick="eliminarObjeto('+id+','+tipo+');"></i>');

                      if ($(el).hasClass("centro")) {
                          var dientePosition = $(el).position();
                          $(el).parent().children("i").css({
                              "position": "absolute",
                              // "top": dientePosition.top*(.79696),
                              // "left": dientePosition.left-15,
                              "top": dientePosition.top*(.35),
                              "left": dientePosition.left-10,
                          });
                      } else {

                          if(tipo_dispositivo == 0 ){
                              //$(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                              $(this).parent().children("i").css({"position": "absolute", "top": "5%", "left": "4.2ex"});
                          }else{
                              $(this).parent().children("i").css({"position": "absolute", "top": "2%", "left": "4ex"});
                              //$(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": ".6ex"});
                          }
                      }
                  }
              });
              break;
          case "fractura":
              $(this).parent().children().each(function(index, el) {
                  if ($(el).hasClass("centro")) {
                      var tipo= 'fractura';
                      $(this).parent().append('<i id="fractura_'+id+'" style="color:red;" class="diagonal fa-3x fa-fw fractura fractura_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                      if ($(el).hasClass("centro")) {
                          var dientePosition = $(el).position();
                          if(tipo_dispositivo == 0 ){
                              $(el).parent().children("i").css({
                                  "z-index": 1,
                                  "position": "absolute",
                                  "top":  dientePosition.top*((-1)*(.69696))-9,//dientePosition.top*(.79696),
                                  "left": dientePosition.left-15,
                              });
                          }else{
                              $(el).parent().children("i").css({
                                  "z-index": 1,
                                  "position": "absolute",
                                  "top":  dientePosition.top*((-1)*(.1)),//dientePosition.top*(.79696),
                                  "left": dientePosition.left+4,
                                  "font-size": "1.5rem",
                              });
                          }
                      }else {
                          $(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                      }
                  }
                  if ($(el).hasClass("centro-leche")) {
                      var tipo= 'fractura';
                      $(this).parent().append('<i id="fractura_'+id+'" style="color:red;" class="diagonal fa-3x fa-fw fractura fractura_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                      if ($(el).hasClass("centro")) {
                          var dientePosition = $(el).position();
                          $(el).parent().children("i").css({
                              "z-index": 1,
                              "position": "absolute",
                              "top":  dientePosition.top*((-1)*(.69696))-9,//dientePosition.top*(.79696),
                              "left": dientePosition.left-15,
                          });
                      }else {
                          if(tipo_dispositivo == 0 ){
                              $(this).parent().children("i").css({"z-index": 1, "position": "absolute", "top": "-46%", "left": "1.2ex"});
                          }else{
                              $(this).parent().children("i").css({"z-index": 1, "position": "absolute", "top": "-15%", "left": "3.7ex", "font-size": "1.5rem"});
                          }
                      }
                  }
              });
              break;
          case "semierupcionado":
              $(this).parent().children().each(function(index, el) {
                  if ($(el).hasClass("centro") || $(el).hasClass("centro-leche")) {
                      var tipo= 'semierupcionado';
                      $(this).parent().append('<i id="semierupcionado_'+id+'" style="color:blue;" class="diagonal fa-3x fa-fw semierupcionado semierupcionado_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                      if ($(el).hasClass("centro")) {
                          var dientePosition = $(el).position();
                          if(tipo_dispositivo == 0 ){
                              $(el).parent().children("i").css({
                                  "z-index": 1,
                                  "position": "absolute",
                                  "top":  dientePosition.top*((-1)*(.69696))-9,//dientePosition.top*(.79696),
                                  "left": dientePosition.left-15,
                              });
                          }else{
                              $(el).parent().children("i").css({
                                  "z-index": 1,
                                  "position": "absolute",
                                  "top":  dientePosition.top*((-1)*(.1)),//dientePosition.top*(.79696),
                                  "left": dientePosition.left+4,
                                  "font-size": "1.5rem",
                              });
                          }
                      }else {
                          $(this).parent().children("i").css({ "z-index": 1, "position": "absolute", "top": "21%", "left": "1.2ex"});
                      }
                  }
                  if ($(el).hasClass("centro-leche")) {
                      var tipo= 'semierupcionado';
                      $(this).parent().append('<i id="semierupcionado_'+id+'" style="color:blue;" class="diagonal fa-3x fa-fw semierupcionado semierupcionado_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                      if ($(el).hasClass("centro")) {
                          var dientePosition = $(el).position();
                          $(el).parent().children("i").css({
                              "z-index": 1,
                              "position": "absolute",
                              "top":  dientePosition.top*((-1)*(.69696))-9,//dientePosition.top*(.79696),
                              "left": dientePosition.left-15,
                          });
                      }else {
                          if(tipo_dispositivo == 0 ){
                              $(this).parent().children("i").css({ "z-index": 1, "position": "absolute", "top": "-46%", "left": "1.2ex"});
                          }else{
                              $(this).parent().children("i").css({ "z-index": 1, "position": "absolute", "top": "-15%", "left": "3.7ex", "font-size": "1.5rem"});;
                          }
                      }
                  }
              });
              break;
          case "extraccionp":
              $(this).parent().children().each(function(index, el) {
                  if ($(el).hasClass("centro") || $(el).hasClass("centro-leche")) {
                      var tipo= 'extraccionp';
                      //$(this).parent().append('<i id="extraccionp_'+id+'" style="color:red;" class="glyphicon glyphicon-remove fa-3x fa-fw extraccionp extraccionp_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                      $(this).parent().append('<i id="extraccionp_'+id+'" style="color:red;" class="cruz extraccionp extraccionp_'+id+'" onclick="eliminarObjeto('+id+', '+tipo+');"></i>');
                      if ($(el).hasClass("centro")) {
                          var dientePosition = $(el).position();
                          $(el).parent().children("i").css({
                              "position": "absolute",
                              // "top": dientePosition.top*(.79696),
                              // "left": dientePosition.left-15,
                              "top": dientePosition.top*(.35),
                              "left": dientePosition.left-10,
                          });
                      }else {
                          if(tipo_dispositivo == 0 ){
                              //$(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": "1.2ex"});
                              $(this).parent().children("i").css({"position": "absolute", "top": "5%", "left": "4.2ex"});
                          }else{
                              $(this).parent().children("i").css({"position": "absolute", "top": "2%", "left": "4ex"});
                              //$(this).parent().children("i").css({"position": "absolute", "top": "21%", "left": ".6ex"});
                          }
                      }
                  }
              });
              break;

          case "puenteprueba":

              var diente1= 'null';
              var diente2= 'null';
              var bordecolor=$(this).parent().children('.cuadro').css('border-color');
              if(bordecolor!='rgb(255, 0, 0)'){
                  if (arrayPuente.length < 1) {
                      diente1= $(this).attr('id');
                      arrayPuente.push({
                          id: $(this).attr('id')
                      });
                      $(this).parent().children('.cuadro').css('border-color', 'blue');
                  } else {
                      diente2= $(this).attr('id');
                      arrayPuente.push({
                          id: $(this).attr('id')
                      });
                      $(this).parent().children('.cuadro').css('border-color', 'blue');
                  }
                  if(arrayPuente.length>1){
                      dibujarPuente(arrayPuente[0].id, arrayPuente[1].id, 'ppr');
                      arrayPuente = [];
                      arrayPuentedientes = [];
                  }
              }else{
                  swal({ title:"Oops...", type: "warning", text: '¡Ya ha sido seleccionado!', timer: 2000, showConfirmButton: false,});
              }

              break;

          case "puente":

              var diente1= 'null';
              var diente2= 'null';
              var bordecolor=$(this).parent().children('.cuadro').css('border-color');
              if(bordecolor!='rgb(0, 0, 255)'){
                  if (arrayPuente.length < 1) {
                      diente1= $(this).attr('id');
                      arrayPuente.push({
                          id: $(this).attr('id')
                      });
                      $(this).parent().children('.cuadro').css('border-color', 'blue');
                  } else {
                      diente2= $(this).attr('id');
                      arrayPuente.push({
                          id: $(this).attr('id')
                      });
                      $(this).parent().children('.cuadro').css('border-color', 'blue');
                  }
                  if(arrayPuente.length>1){
                      dibujarPuente(arrayPuente[0].id, arrayPuente[1].id, 'puente');
                      arrayPuente = [];
                      arrayPuentedientes = [];
                  }
              }else{
                  swal({ title:"Oops...", type: "warning", text: '¡Ya ha sido seleccionado!', timer: 2000, showConfirmButton: false,});
              }

              break;
          case "ppr":
              var diente1= 'null';
              var diente2= 'null';
              var bordecolor=$(this).parent().children('.cuadro').css('border-color');
              if(bordecolor!='rgb(0, 0, 255)'){
                  if (arrayPuente.length < 1) {
                      diente1= $(this).attr('id');
                      arrayPuente.push({
                          id: $(this).attr('id')
                      });
                      $(this).parent().children('.cuadro').css('border-color', 'blue');
                  } else {
                      diente2= $(this).attr('id');
                      arrayPuente.push({
                          id: $(this).attr('id')
                      });
                      $(this).parent().children('.cuadro').css('border-color', 'blue');
                  }
                  if(arrayPuente.length>1){
                      dibujarPuente(arrayPuente[0].id, arrayPuente[1].id, 'ppr');
                      arrayPuente = [];
                      arrayPuentedientes = [];
                  }
              }else{
                  swal({ title:"Oops...", type: "warning", text: '¡Ya ha sido seleccionado!', timer: 2000, showConfirmButton: false,});
              }

              break;

          case "puentem":

              var diente1= 'null';
              var diente2= 'null';
              var bordecolor=$(this).parent().children('.cuadro').css('border-color');
              if(bordecolor!='rgb(255, 0, 0)'){
                  if (arrayPuente.length < 1) {
                      diente1= $(this).attr('id');
                      arrayPuente.push({
                          id: $(this).attr('id')
                      });
                      $(this).parent().children('.cuadro').css('border-color', 'red');
                  } else {
                      diente2= $(this).attr('id');
                      arrayPuente.push({
                          id: $(this).attr('id')
                      });
                      $(this).parent().children('.cuadro').css('border-color', 'red');
                  }
                  if(arrayPuente.length>1){
                      dibujarPuente(arrayPuente[0].id, arrayPuente[1].id, 'puentem');
                      arrayPuente = [];
                      arrayPuentedientes = [];
                  }
              }else{
                  swal({ title:"Oops...", type: "warning", text: '¡Ya ha sido seleccionado!', timer: 2000, showConfirmButton: false,});
              }

              break;

          case "borrar":
              cambiarColor($(this), 'click-clear');
              break;

          default:
              console.log("borrar case");
      }
      return false;
  });
}

function guardarOdontograma() {

    var dientes=[
                '18', '17', '16', '15', '14', '13', '12', '11',
                '28', '27', '26', '25', '24', '23', '22', '21',
                '38', '37', '36', '35', '34', '33', '32', '31',
                '48', '47', '46', '45', '44', '43', '42', '41',
                '55', '54', '53', '52', '51',
                '65', '64', '63', '62', '61',
                '75', '74', '73', '72', '71',
                '85', '84', '83', '82', '81',
                ];

    var datosdientes=[];

    dientes.forEach(function(element) {

        var diente=[];
        var infodiente=[];
        var caries=[];
        var obturacionmal=[];
        var arraydePuente=[];
        var arraydePuentem=[];
        var arraydePPR=[];
        var arraydeExtraccion=[];
        var arraydeSano=[];
        var arraydeRestoradicular=[];
        var arraydeDesgaste=[];
        var arraydeMovil=[];
        var arraydeEndo=[];
        var arraydeImplante=[];
        var arraydeExtraccionortodoncia=[];
        var arraydeFractura=[];
        var arraydeSemierupcionado=[];
        var arraydeExtraccionp=[];

        $("div#diente"+element).children('.click').each(  //funcion para obtener los dientes con fractura , obturacion o extraccion
            function(index, el) {
                var color='null';
                if( $(el).hasClass("click-red")){color='red';}
                if( $(el).hasClass("click-blue")){color='blue';}
                if( $(el).hasClass("click-delete")){color='delete';}
                if( $(el).hasClass("corona")){color='corona';}
                //if( $(el).attr('style')){color='puente';}
                //console.log('diente '+$(el).attr('id')+ ' tiene rojo');
                var partediente=[$(el).attr('id'), color];
                infodiente.push(partediente);
            }
        );

        $("div#diente"+element).children('.obturacionmal').each(  //funcion para obtener los dientes con fractura , obturacion o extraccion
            function(index, el) {
                var str = $(el).attr('id');             //obtiene el identificador de la extraccion
                var arrayObturacionmal = str.split("_"); //separa la cadena en un arreglo, en donde se encuentre un guion bajo
                obturacionmal.push(arrayObturacionmal);
                //console.log(JSON.stringify(arraydeExtraccion));
            }
        );

        $("div#diente"+element).children('.carie').each(  //funcion para obtener los dientes con fractura , obturacion o extraccion
            function(index, el) {
                var str = $(el).attr('id');             //obtiene el identificador de la extraccion
                var arrayCaries = str.split("_"); //separa la cadena en un arreglo, en donde se encuentre un guion bajo
                caries.push(arrayCaries);
                //console.log(JSON.stringify(arraydeExtraccion));
            }
        );

        $("div#diente"+element).children('.puente').each(
            function(index, el) {
                var str = $(el).attr('id');             //obtiene el identificador del puente
                arraydePuente = str.split("_");     //separa la cadena en un arreglo, en donde se encuentre un guion bajo
                //console.log(JSON.stringify(arraydePuente));
            }
        );

        $("div#diente"+element).children('.puentem').each(
            function(index, el) {
                var str = $(el).attr('id');             //obtiene el identificador del puente
                arraydePuentem = str.split("_");     //separa la cadena en un arreglo, en donde se encuentre un guion bajo
                //console.log(JSON.stringify(arraydePuente));
            }
        );

        $("div#diente"+element).children('.ppr').each(
            function(index, el) {
                var str = $(el).attr('id');             //obtiene el identificador del puente
                arraydePPR = str.split("_");     //separa la cadena en un arreglo, en donde se encuentre un guion bajo
                //console.log(JSON.stringify(arraydePuente));
            }
        );

        $("div#diente"+element).children('.sano').each(
            function(index, el) {
                var str = $(el).attr('id');             //obtiene el identificador de la extraccion
                arraydeSano = str.split("_"); //separa la cadena en un arreglo, en donde se encuentre un guion bajo
                //console.log(JSON.stringify(arraydeExtraccion));
            }
        );

        $("div#diente"+element).children('.restoradicular').each(
            function(index, el) {
                var str = $(el).attr('id');             //obtiene el identificador de la extraccion
                arraydeRestoradicular = str.split("_"); //separa la cadena en un arreglo, en donde se encuentre un guion bajo
                //console.log(JSON.stringify(arraydeExtraccion));
            }
        );

        $("div#diente"+element).children('.desgaste').each(
            function(index, el) {
                var str = $(el).attr('id');             //obtiene el identificador de la extraccion
                arraydeDesgaste = str.split("_"); //separa la cadena en un arreglo, en donde se encuentre un guion bajo
                //console.log(JSON.stringify(arraydeExtraccion));
            }
        );

        $("div#diente"+element).children('.endo').each(
            function(index, el) {
                var str = $(el).attr('id');             //obtiene el identificador de la extraccion
                arraydeEndo = str.split("_"); //separa la cadena en un arreglo, en donde se encuentre un guion bajo
                //console.log(JSON.stringify(arraydeExtraccion));
            }
        );

        $("div#diente"+element).children('.implante').each(
            function(index, el) {
                var str = $(el).attr('id');             //obtiene el identificador de la extraccion
                arraydeImplante = str.split("_"); //separa la cadena en un arreglo, en donde se encuentre un guion bajo
                //console.log(JSON.stringify(arraydeExtraccion));
            }
        );

        $("div#diente"+element).children('.movil').each(
            function(index, el) {
                var str = $(el).attr('id');             //obtiene el identificador de la extraccion
                arraydeMovil = str.split("_"); //separa la cadena en un arreglo, en donde se encuentre un guion bajo
                //console.log(JSON.stringify(arraydeExtraccion));
            }
        );

        $("div#diente"+element).children('.extracciono').each(
            function(index, el) {
                var str = $(el).attr('id');             //obtiene el identificador de la extraccion
                arraydeExtraccionortodoncia = str.split("_"); //separa la cadena en un arreglo, en donde se encuentre un guion bajo
                //console.log(JSON.stringify(arraydeExtraccion));
            }
        );

        $("div#diente"+element).children('.fractura').each(
            function(index, el) {
                var str = $(el).attr('id');             //obtiene el identificador de la extraccion
                arraydeFractura = str.split("_"); //separa la cadena en un arreglo, en donde se encuentre un guion bajo
                //console.log(JSON.stringify(arraydeExtraccion));
            }
        );

        $("div#diente"+element).children('.semierupcionado').each(
            function(index, el) {
                var str = $(el).attr('id');             //obtiene el identificador de la extraccion
                arraydeSemierupcionado = str.split("_"); //separa la cadena en un arreglo, en donde se encuentre un guion bajo
                //console.log(JSON.stringify(arraydeExtraccion));
            }
        );

        $("div#diente"+element).children('.extraccionp').each(
            function(index, el) {
                var str = $(el).attr('id');             //obtiene el identificador de la extraccion
                arraydeExtraccionp = str.split("_"); //separa la cadena en un arreglo, en donde se encuentre un guion bajo
                //console.log(JSON.stringify(arraydeExtraccion));
            }
        );

        diente.push(
                {'caries':caries},
                {'infodiente':infodiente},
                {'puente':arraydePuente},
                {'sano':arraydeSano},
                {'restoradicular':arraydeRestoradicular},
                {'extraccionortodoncia':arraydeExtraccionortodoncia},
                {'fractura':arraydeFractura},
                {'semierupcionado':arraydeSemierupcionado},
                {'extraccionp':arraydeExtraccionp},
                {'obturacionmal':obturacionmal},
                {'puentem':arraydePuentem},
                {'ppr':arraydePPR},
                {'des':arraydeDesgaste},  //desgaste
                {'mvl':arraydeMovil},  //movil
                {'endo':arraydeEndo},  //endodoncia
                {'implante':arraydeImplante}  //implante
            );
        var dd=[element, diente]
        datosdientes.push(dd);

    });

    return JSON.stringify(datosdientes);

}
