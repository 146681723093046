function alertaRegistroExitoso(){
    $(document).ready(function(){
	    swal({ title:"¡Registro Exitoso!", type: "success", timer: 1500, showConfirmButton: false,});
	});
}
function alertaActualizacionExitoso(){
    $(document).ready(function(){
	    swal({ title:"¡Actualización Exitosa!", type: "success", timer: 1500, showConfirmButton: false,});
	});
}
function alertaEliminacionExitoso(){
    $(document).ready(function(){
	    swal({ title:"¡Eliminación Exitosa!", type: "success", timer: 1500, showConfirmButton: false,});
	});
}
function alertaLogin(){
    $(document).ready(function(){
	    swal({ title:"Oops...", type: "error", text: '¡Verifica tu correo o contraseña!', timer: 2000, showConfirmButton: false,});
	});
}
function showError(){
    $(document).ready(function(){
      swal({ title:"Oops...", type: "error", text: '¡No se puede eliminar este registro!', timer: 2000, showConfirmButton: false,});
  });
}
function showNotification(from, align){
  color = 'info';

  $.notify({
      icon: "now-ui-icons emoticons_satisfied",
      message: "!Genial! ¡Haz regresado!, Bienvenido."
    },{
        type: color,
        timer: 2000,
        placement: {
            from: from,
            align: align
        }
    });
}

function habilitarCampos(){
    $('#pacpacientes-nombre').removeAttr("readOnly");
    $('#pacpacientes-ape_pat').removeAttr("readOnly");
    $('#pacpacientes-ape_mat').removeAttr("readOnly");
    $('#pacpacientes-sexo').removeAttr("readOnly");
    $('#pacpacientes-fecha_nac').removeAttr("readOnly");
    $('#pacpacientes-tel').removeAttr("readOnly");
    $('#pacpacientes-direccion').removeAttr("readOnly");
    $('#pacpacientes-correo').removeAttr("readOnly");
    $('#pacpacientes-ocupacion').removeAttr("readOnly");
    $('#pacpacientes-escoloridad').removeAttr("readOnly");
    $('#pacpacientes-grado').removeAttr("readOnly");
    $('#pacpacientes-rfc').removeAttr("readOnly");
    $('#guardar').removeAttr("style");
    $('#habilitarCampos').removeAttr("class");
    $('#habilitarCampos').attr("class","btn btn-danger");
    $('#habilitarCampos').removeAttr("onclick");
    $('#habilitarCampos').attr("onclick","deshabilitarCampos()");
    document.getElementById("habilitarCampos").innerHTML = "<span class='glyphicon glyphicon-remove'></span> Cancelar Edición";
}

function deshabilitarCampos(){
    $('#pacpacientes-nombre').attr("readOnly","readOnly");
    $('#pacpacientes-ape_pat').attr("readOnly","readOnly");
    $('#pacpacientes-ape_mat').attr("readOnly","readOnly");
    $('#pacpacientes-sexo').attr("readOnly","readOnly");
    $('#pacpacientes-fecha_nac').attr("readOnly","readOnly");
    $('#pacpacientes-tel').attr("readOnly","readOnly");
    $('#pacpacientes-direccion').attr("readOnly","readOnly");
    $('#pacpacientes-correo').attr("readOnly","readOnly");
    $('#pacpacientes-ocupacion').attr("readOnly","readOnly");
    $('#pacpacientes-escoloridad').attr("readOnly","readOnly");
    $('#pacpacientes-grado').attr("readOnly","readOnly");
    $('#pacpacientes-rfc').attr("readOnly","readOnly");
    $('#guardar').attr("style","display:none;");
    $('#habilitarCampos').removeAttr("class");
    $('#habilitarCampos').attr("class","btn btn-info");
    $('#habilitarCampos').removeAttr("onclick");
    $('#habilitarCampos').attr("onclick","habilitarCampos()");
    document.getElementById("habilitarCampos").innerHTML = "<span class='glyphicon glyphicon-pencil'></span> Editar Datos";
}

function eliminarPregunta(url){
    swal({
            title: '¿Está seguro de eliminar este elemento?',
            type: 'warning',
            showCancelButton: true,
            allowOutsideClick: true
        }).catch(swal.noop).then(function(result){
            if (result) {
                $.LoadingOverlay("show");
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    success: function(response) {
                        if (response.data.success == true) {
                            $('#contenedor').load(response.data.url);
                            $.LoadingOverlay("hide");
                            swal({ title:'¡Eliminado!', type: 'success', text: '', timer: 3000, showConfirmButton: false,});
                        }else{
                            $.LoadingOverlay("hide");
                            swal({ title:'Cuidado', type: 'warning', text: 'No se puede eliminar este elemento', showConfirmButton: true,});
                        }
                        $('#modal_archivos').modal('hide');
                        $('#modal').modal('hide');
                        $('body').removeClass('modal-open');
                        $('.modal-backdrop').remove();
                    }
                });
            }
        });
    return false;
};

function ejecutarPeticionajax(url, data, tipo, modal){
    $.LoadingOverlay("show");
    switch (tipo){
        case 'normal':
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: data,
                    success: function(response) {
                        if (response.data.success == true) {
                            if(response.data.url!=null){
                                $('#contenedor').load(response.data.url);
                            }
                            $.LoadingOverlay("hide");
                            swal({ title:'¡Registrado!', type: "success", text: "", timer: 3000, showConfirmButton: false,});
                        }else{
                            $.LoadingOverlay("hide");
                            swal({ title:"Faltan Datos", type: "warning", text: 'No se ha podido registrar', showConfirmButton: true,});
                        }
                    }
                });
            break;
        case 'modal':
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: data,
                    success: function(response) {
                        $('#'+modal).modal('hide');
                        $('body').removeClass('modal-open');
                        $('.modal-backdrop').remove();
                        if (response.data.success == true) {

                            if(response.data.url!=null){
                                $('#contenedor').load(response.data.url);
                            }
                            $.LoadingOverlay("hide");
                            swal({ title:'¡Registrado!', type: "success", text: "", timer: 3000, showConfirmButton: false,});
                        }else{
                            $.LoadingOverlay("hide");
                            swal({ title:"Faltan Datos", type: "warning", text: 'No se ha podido registrar', showConfirmButton: true,});
                        }
                    }
                });
            break;
        case 'archivo':
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: data,
                    contentType: false,
                    processData: false,
                    success: function(response) {
                        $('#'+modal).modal('hide');
                        $('body').removeClass('modal-open');
                        $('.modal-backdrop').remove();
                        if (response.data.success == true) {
                            if(response.data.url!=null){
                                $('#contenedor').load(response.data.url);
                            }
                            $.LoadingOverlay("hide");
                            swal({ title:'¡Registrado!', type: "success", text: "", timer: 3000, showConfirmButton: false,});
                        }else{
                            $.LoadingOverlay("hide");
                            swal({ title:"Faltan Datos", type: "warning", text: 'No se ha podido registrar', showConfirmButton: true,});
                        }
                    }
                });
            break;
        case 'contenedor':
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    success: function(response) {
                        $('#contenedor').html(response);
                        $.LoadingOverlay("hide");
                    }
                });
            break;
        case 'custom':
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: data,
                    success: function(response) {
                        $('#'+modal).modal('hide');
                        $('body').removeClass('modal-open');
                        $('.modal-backdrop').remove();
                        if (response.data.success == true) {
                            if(response.data.url!=null){
                                $('#contenedor').load(response.data.url);
                            }
                            $.LoadingOverlay("hide");
                            swal({ title:'¡Registrado!', type: "success", text: "", timer: 3000, showConfirmButton: false,});
                        }else{
                            $.LoadingOverlay("hide");
                            swal({ title:"¡Alerta!", type: "warning", text: response.data.message , showConfirmButton: true,});
                        }
                    }
                });
            break;
    }
    $.LoadingOverlay("hide");
}

function ejecutarSalvadoodontograma(url, data, tipo, modal){
    switch (tipo){
        case 'normal':
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: data,
                success: function(response) {
                    if (response.data.success == true) {
                        if(response.data.url!=null){
                            $('#contenedor').load(response.data.url);
                        }
                        $.LoadingOverlay("hide");
                        swal({ title:'¡Registrado!', type: "success", text: "", timer: 3000, showConfirmButton: false,});
                    }else{
                        $.LoadingOverlay("hide");
                        swal({ title:"Faltan Datos", type: "warning", text: 'No se ha podido registrar', showConfirmButton: true,});
                    }
                }
            });
        break;
    }
}

function eliminar(url){
    swal({
            title: '¿Está seguro de eliminar este elemento?',
            text: 'Se eliminará datos relacionado al mismo',
            type: 'warning',
            showCancelButton: true,
            allowOutsideClick: true
        }).catch(swal.noop).then(function(result){
            if (result) {
                $.LoadingOverlay("show");
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    success: function(response) {
                        if (response.data.success == true) {
                            $('#contenedor').load(response.data.url);
                            $.LoadingOverlay("hide");
                            swal({ title:'¡Eliminado!', type: 'success', text: '', timer: 3000, showConfirmButton: false,});
                        }else{
                            $.LoadingOverlay("hide");
                            swal({ title:'Cuidado', type: 'warning', text: 'No se puede eliminar este elemento', showConfirmButton: true,});
                        }
                    }
                });
                $.LoadingOverlay("hide");
            }
        });
    return false;
}

function visualizar(url){
    $.LoadingOverlay("show");
    $("#contenedor").load(url, function(responseTxt, statusTxt, xhr){
        if(statusTxt == "success")
            $.LoadingOverlay("hide");
        if(statusTxt == "error")
            $.LoadingOverlay("hide");
    });
}

function buscarCoincidenciasconnsultas(url, fecha, hora){
    data={'fecha':fecha, 'hora':hora};
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: data,
        success: function(response) {
            if (response.data.success == true) {
                swal({ title:'¡Alerta!', type: "warning", text: "Ya existe otra cita en la hora y fecha seleccionada", timer: 3000, showConfirmButton: true,});
            }
        }
    });
}

function ejecutarCambiodeestado(url){
    swal({
            title: '¿Está seguro de cambiar su estado?',
            type: 'warning',
            showCancelButton: true,
            allowOutsideClick: true
        }).catch(swal.noop).then(function(result){
            if (result) {
                $.LoadingOverlay("show");
                $.ajax({
                    url: url,
                    success: function(response) {
                        if (response.data.success == true) {
                            if(response.data.url!=null){
                                $('#contenedor').load(response.data.url);
                            }
                            $.LoadingOverlay("hide");
                            swal({ title:'¡Registrado!', type: "success", text: "", timer: 3000, showConfirmButton: false,});
                        }else{
                            $.LoadingOverlay("hide");
                            swal({ title:"Oops...", type: "warning", text: 'No se ha podido registrar', showConfirmButton: true,});
                        }
                    }
                });
                $.LoadingOverlay("hide");
            }
        });
    return false;
}

function ejecutarRetiro(url){
    swal({
            title: '¿Está seguro de cambiar su estado?',
            type: 'warning',
            showCancelButton: true,
            allowOutsideClick: true
        }).catch(swal.noop).then(function(result){
            if (result) {
                $.LoadingOverlay("show");
                $.ajax({
                    url: url,
                    success: function(response) {
                        if (response.data.success == true) {
                            if(response.data.url!=null){
                                $('#contenedor').load(response.data.url);
                            }
                            if(response.data.reload!=null){
                                location.reload();
                            }
                            $.LoadingOverlay("hide");
                            swal({ title:'¡Registrado!', type: "success", text: "", timer: 3000, showConfirmButton: false,});
                        }else{
                            $.LoadingOverlay("hide");
                            swal({ title:"Oops...", type: "warning", text: 'No se ha podido registrar', showConfirmButton: true,});
                        }
                    }
                });
                $.LoadingOverlay("hide");
            }
        });
    return false;
}

function ejecutarCancelacion(url){
    swal({
        title: '¿Está seguro de cancelar el movimiento? Menciona el Motivo',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'CONFIRMAR',
        showLoaderOnConfirm: true,
        preConfirm: (concepto) => {

            $.ajax({
                url: url,
                data: {"mensaje" : concepto},
                type: 'post',
                dataType: 'json',
                async: false,
                cache: false,
                timeout: 30000,
                error: function(error){
                    Swal.showValidationMessage(
                        `Solicitud Fallida`
                    )
                },
                success: function(response) {
                    if (response.data.success == true) {
                        if(response.data.url!=null){
                            $('#contenedor').load(response.data.url);
                        }
                        if(response.data.reload!=null){
                            location.reload();
                        }
                        swal({ title:'¡Registrado!', type: "success", text: "", timer: 3000, showConfirmButton: false,});
                    }else{
                        swal({ title:"Oops...", type: "warning", text: response.data.message, showConfirmButton: true,});
                    }
                }
            });

        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.value) {
            swal({ title:'¡Registrado!', type: "success", text: "", timer: 3000, showConfirmButton: false,});
        }
      })

//       swal({
//         title: '¿Está seguro de cancelar el movimiento?',
//         type: 'text',
//         showCancelButton: true,
//         allowOutsideClick: true
//     }).catch(swal.noop).then(function(result){
//         if (result) {
//             $.LoadingOverlay("show");
//             $.ajax({
//                 url: url,
//                 success: function(response) {
//                     if (response.data.success == true) {
//                         if(response.data.url!=null){
//                             $('#contenedor').load(response.data.url);
//                         }
//                         if(response.data.reload!=null){
//                             location.reload();
//                         }
//                         $.LoadingOverlay("hide");
//                         swal({ title:'¡Registrado!', type: "success", text: "", timer: 3000, showConfirmButton: false,});
//                     }else{
//                         $.LoadingOverlay("hide");
//                         swal({ title:"Oops...", type: "warning", text: response.data.message, showConfirmButton: true,});
//                     }
//                 }
//             });
//             $.LoadingOverlay("hide");
//         }
//     });
// return false;


}

function checkNotification(url){
    $.ajax({
        url: url,
        success: function(response) {
            if (response.data.success == true) {
                $('#notificacionCitas').html(response.data.cantidad);
                $('#notificacionCitas').removeClass('d-none');
            }else{
                $('#notificacionCitas').html('');
                $('#notificacionCitas').addClass('d-none');
            }
        }
    });
}

function checkCitas(url){
    $.ajax({
        url: url,
    });
}

function mostrarTipo(Radio) {

    switch (Radio.value){
        case 'sin':
            $("#cajmovimientoscaja-id_paciente").val("").trigger("change");
            $( "#sin_tratamiento" ).slideDown( "slow" );
            $( "#con_tratamiento" ).slideUp( "slow" );
            $( "#contenedor_tratamiento" ).slideUp( "slow" );
            break;
        case 'con':
            $( "#con_tratamiento" ).slideDown( "slow" );
            $( "#sin_tratamiento" ).slideUp( "slow" );
            break;
    }

}

function buscarTratamiento(url){
    $.LoadingOverlay("show");
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        success: function(response) {
            if (response.data.success == true) {
                // if(response.data.url!=null){
                //     $('#datos_tratamiento').load(response.data.url);
                // }
                $('#datos_tratamiento').html(response.data.resultado);
                $.LoadingOverlay("hide");
            }else{
                $('#datos_tratamiento').html('<p class="h3" style="margin-top: 0px;">Tratamiento sin adeudos.</p>');
                $.LoadingOverlay("hide");
            }
        }
    });
    return false;
}

function acciones(url){
    $.LoadingOverlay("show");
    $.ajax({
        url: url,
        // type: 'post',
        // dataType: 'json',
        success: function(response) {
            $('#contenedor').html(response);
            $.LoadingOverlay("hide");
        },
        error: function (xhr, ajaxOptions, thrownError) {
            acciones(url);
        }
    });
    return false;
}

function guardarMarca() {
    var data = $(".formulariomarcas").serializeArray();
    var url = $(".formulariomarcas").attr("action");

    $.LoadingOverlay("show");
        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: data,
    });
    $.LoadingOverlay("hide");

    $.pjax.reload({container: "#contenedor-marcas"});
    $.pjax.reload({container: "#contenedor-marcas", async:false});

    return false;
}

function guardarModelo() {
    var data = $(".formulariomodelos").serializeArray();
    var url = $(".formulariomodelos").attr("action");

    $.LoadingOverlay("show");
        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: data,
    });
    $.LoadingOverlay("hide");

    $.pjax.reload({container:"#contenedor-modelos"});  //Reload GridView

    return false;
}

function notificacionEtiqueta(from, align, mensaje, tipo){

    color = null;
    switch(tipo){
        case "success":
            color = 'success';
            break;
        case "error":
            color = 'danger';
            break;
        case "alert":
            color = 'alert';
            break;
        case "info":
            color = 'info';
            break;
    }

    $.notify({
        //icon: "now-ui-icons emoticons_satisfied",
        message: mensaje
      },{
          type: color,
          timer: 2000,
          placement: {
              from: from,
              align: align
          }
      });
    }

    function cancelarCita(url){
        swal({
            title: '¿Está seguro de cancelar esta Cita? Menciona el Motivo',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'CONFIRMAR',
            showLoaderOnConfirm: true,
            preConfirm: (concepto) => {

                $.ajax({
                    url: url,
                    data: {"motivo" : concepto},
                    type: 'post',
                    dataType: 'json',
                    async: false,
                    cache: false,
                    timeout: 30000,
                    error: function(error){
                        Swal.showValidationMessage(
                            `Solicitud Fallida`
                        )
                    },
                    success: function(response) {
                        if (response.data.success == true) {
                            if(response.data.url!=null){
                                $('#contenedor').load(response.data.url);
                            }
                            if(response.data.reload!=null){
                                location.reload();
                            }
                            swal({ title:'¡Registrado!', type: "success", text: "", timer: 3000, showConfirmButton: false,});
                        }else{
                            swal({ title:"Oops...", type: "warning", text: response.data.message, showConfirmButton: true,});
                        }
                    }
                });

            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.value) {
                swal({ title:'¡Registrado!', type: "success", text: "", timer: 3000, showConfirmButton: false,});
            }
          })
    }




